import './App.css';
import Header from './comps/Header/Header';


function App() {

   


  return (
    <div className="App">
     
      <Header />  

    </div>
  );
}

export default App;
